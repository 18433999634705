import React, { useEffect, useState } from 'react';
import { getTodayDate, getFormatDate } from '../utility';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from '../Redux/filterSlice';
import Dropdown from 'react-bootstrap/Dropdown';
import Countinc from './Countinc';
import { addcity } from '../Redux/citySlice';
import Axios from "../Axios";
import { updateLoader } from '../Redux/loaderSlice';
import Select, { components } from 'react-select';
import SearchIcon from '../icon/locationicon.png'; 

const Search = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const city = useSelector((state) => state.city);
    const filers = useSelector((state) => state.filers);
    const country = useSelector((state) => state.country);
    const { beforeToday } = DateRangePicker;
    const [capacity, setCapacity] = useState(10);
    const [error, setError] = useState('');
    const [cityList, setCity] = useState();
    const [selectedDateRange, setSelectedDateRange] = useState([new Date(filers.check_in), new Date(filers.check_out)]);
    const [selected, setSelected] = useState(null);
    function useWindowWidth() {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowWidth;
    }
    const windowWidth = useWindowWidth();
    const showOneCalendar = windowWidth <= 481;
    useEffect(() => {
        const storedDateRange = JSON.parse(localStorage.getItem('dateRange'));
        if (storedDateRange) {
            setSelectedDateRange([new Date(storedDateRange[0]), new Date(storedDateRange[1])]);
            dispatch(updateFilters({ check_in: getFormatDate(new Date(storedDateRange[0])), check_out: getFormatDate(new Date(storedDateRange[1])) }));
        } else {
            setSelectedDateRange([new Date(filers.check_in), new Date(filers.check_out)]);
        }

        const storedCity = JSON.parse(localStorage.getItem('country_ids'));
        if (storedCity) {
            setSelected(storedCity);
            dispatch(updateFilters({ country_ids: storedCity.value }));
            getCity(storedCity.value);
        }
    }, [dispatch, filers.check_in, filers.check_out]);


    const handleDateChange = (range) => {
        if (range && range.length === 2) {
            const [startDate, endDate] = range;

            // Normalize dates to remove time and compare only dates
            const start = new Date(startDate.toDateString());
            const end = new Date(endDate.toDateString());

            if (start.getTime() === end.getTime()) {
                alert("Check-in and check-out dates cannot be the same. Please select different dates.");
                return;
            }

            // Proceed if dates are different
            setSelectedDateRange(range);
            dispatch(updateFilters({ check_in: getFormatDate(range[0]), check_out: getFormatDate(range[1]) }));
            localStorage.setItem('dateRange', JSON.stringify(range));
        }
    };


    const getCity = async (e) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const resp = await Axios.get('/citys/' + e);
            if (resp.data.code === "EC200") {
                dispatch(addcity(resp.data.data.data));
            }
            dispatch(updateLoader({ loader: false }));
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
            dispatch(updateLoader({ loader: false }));
        }
    };

    useEffect(() => {
        getCity(filers.category);
    }, [filers.category]);

    const transformOptions = (country) => {
        return country.map((c) => ({
            value: c.id,
            label: c.name,
        }));
    };

    const CustomOption = ({ innerRef, innerProps, data }) => (
        <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
            <img src={SearchIcon} alt="" style={{ marginRight: 10 }} />
            {data.label}
        </div>
    );

    const CustomMenu = (props) => {
        return props.selectProps.inputValue ? <components.Menu {...props} /> : null;
    };



    const handleUpdate = (e) => {
        if (e < 1) {
            setError(``);
        } else {
            setError('');
            dispatch(updateFilters({ rooms: e }));
        }
    };
    const handleUpdateGuest = (e) => {
        if (e < 1) {
            setError(``);
        } else {
            setError('');
            dispatch(updateFilters({ adults: e }));
        }
    };

    useEffect(()=>{
        dispatch(updateFilters({ country_ids: null }));
        dispatch(updateFilters({ city_ids: null })); 
    },[])

    const orientation = window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal';

    return (
        <>
            <div className='container'>
                <div className="bar">
                    <div className="location gest_search">
                        <div className='gest_search_adult'>
                            <div className="search_icone_herobox1">
                                <img src="../icon/locationicon.png" alt="Location Icon" />
                            </div>
                            <div className="input_box">
                                <div className="searchmain_title">Select Country</div>
                                <Select
                                    className='search_destination'
                                    options={transformOptions(country)}
                                    value={selected}  // Use null when no option is selected
                                    onChange={(e) => {
                                        dispatch(updateFilters({ country_ids: e.value }));
                                        dispatch(updateFilters({ city_ids: null })); 
                                        localStorage.setItem('country_ids', e.value);
                                        getCity(e.value);
                                        setSelected(e); 
                                        setCity(null)
                                    }}
                                    getOptionLabel={(e) => e.label}
                                    formatOptionLabel={(data) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {data.label}
                                        </div>
                                    )}
                                    placeholder="Select country"
                                    components={{ Option: CustomOption }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="location gest_search">
                        <div className='gest_search_adult'>
                            <div className="search_icone_herobox1">
                                <img src="../icon/locationicon.png" alt="Location Icon" />
                            </div>
                            <div className="input_box">
                                <div className="searchmain_title">Select City</div>
                                <Select
                                    className='search_destination'
                                    options={transformOptions(city)}
                                    value={cityList}  // Use null when no option is selected
                                    onChange={(e) => {
                                        dispatch(updateFilters({ city_ids: e.value })); 
                                        setCity({value:e.value,label:e.label})
                                    }}
                                    getOptionLabel={(e) => e.label}
                                    formatOptionLabel={(data) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {data.label}
                                        </div>
                                    )}
                                    placeholder="Select City"
                                    components={{ Option: CustomOption }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="check-in gest_search">
                        <div className='gest_search_adult'>
                            <div className="search_icone_herobox1">
                                <img src="icon/checkin.png" alt="Checkin Icon" />
                            </div>
                            <div className="input_box">
                                <div className="searchmain_title">Check in / Check out</div>
                                <DateRangePicker
                                    size="xs"
                                    placeholder={'Please select check in/out date'}
                                    format="dd MMM yy"
                                    character={" - "}
                                    showHeader={false}
                                    showOneCalendar={showOneCalendar}
                                    className='checkincheckout_date'
                                    orientation={orientation}
                                    onOk={handleDateChange}
                                    shouldDisableDate={beforeToday()}
                                    cleanable={false}
                                    value={selectedDateRange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="guests gest_search">
                        <div className="search_icone_herobox1">
                            <img src="icon/geust.png" className='img-fluid' />
                        </div>
                        <Dropdown className='adult_dropdown'>
                            <Dropdown.Toggle variant="none" style={{ border: "none", padding: "0" }}>
                                <div className='gest_search_adult'>
                                    <div className="input_box">
                                        <div className="searchmain_title">Adults</div>
                                        <div className='serach_input'>{`${filers.adults} Guests, ${filers.children} Children, ${filers.infants} Infants, ${filers.rooms} Rooms `}</div>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "135%" }} className='adult_dropdown_home'>
                                <div className='adultdropdown_titlename'>Who’s coming?</div>
                                <Countinc title="Adults" update={handleUpdateGuest} value={filers.adults} />
                                <Countinc title="Children" update={(e) => dispatch(updateFilters({ children: e }))} value={filers.children} />
                                <Countinc title="Infants" update={(e) => dispatch(updateFilters({ infants: e }))} value={filers.infants} />
                                <Countinc title="Room" update={handleUpdate} value={filers.rooms} />
                                {error && <p className='adult_alert_error'>{error}</p>}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="guests_search_btn">
                        <button onClick={() => navigate('/product-filter')}>
                            <i className="fa fa-search" />
                            {location.pathname !== '/product-filter' && ('Search')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;
