import { createSlice } from "@reduxjs/toolkit";
import { getTodayDate } from "../utility";

const initialState = {
    category: 1,
    country_ids:1,
    city_ids:null,
    check_in: getTodayDate(),
    check_out: getTodayDate(1),
    adults: 1,
    children: 0,
    infants: 0,
    rooms: 1,
    bedroom: 1,
    orderbytype: 'rating',
    orderby: 'DESC',
}

const filterSlice  = createSlice({
    name:"filter",
    initialState,
    reducers:{
        updateFilters: (state, action) =>{
            return {...state , ...action.payload}
        },
        resetFilter:(state)=>{
            return {...initialState}
        }, 
    }
})
export default filterSlice.reducer; 
export const {updateFilters, resetFilter} = filterSlice.actions;

